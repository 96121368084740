import * as t from 'io-ts';

import { answerWidget, answerWidgetsSchema, StoredAnswersType } from '@tr-common';

export const draftValueSchema = t.union([
  t.boolean, t.string, t.array(t.string)
], 'draftValueSchema');
export type DraftValueType = t.TypeOf<typeof draftValueSchema>;

export const draftAnswerSchema = t.type({
  question: t.string,
  question_option: t.string,
  value: draftValueSchema,
  widget: answerWidgetsSchema
}, 'draftAnswerSchema');
export type DraftAnswerType = t.TypeOf<typeof draftAnswerSchema>;
export type DraftAnswersType = DraftAnswerType[];

export interface DraftAnswer {
  answers: DraftAnswersType;
  validity: boolean;
}

export const extractStoredAnswerFromDraft = (
  src: DraftAnswersType
): StoredAnswersType => src.map(({question, question_option, value, widget}) => {
  const storedValue: string = widget === answerWidget.multiselectText ? JSON.stringify(value) : value.toString();

  return {question, question_option, value: storedValue};
});
