import { Component, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { FormControlErrorHandler, isRealValue } from '@tr-common';

import { getAsyncMessages, hasAsyncErrors } from '../../../models';

@Component({
  selector: 'lib-form-control-error',
  template: `
    <ng-container *ngFor="let errorHandler of errorHandlers">
      <div *ngIf="errorHandler.mustBeShowed(control)" class="capitalize-first-letter">{{ applyMessage(errorHandler.message) }}</div>
    </ng-container>

    <ng-container *ngIf="isInvalidState">
      <div *ngFor="let message of getAsyncMessages(control.errors, 'asyncErrors')">{{ message }}</div>
      <!-- should not show warnings if there are errors -->
      <ng-container *ngIf="!hasAsyncErrors(control.errors)">
        <div *ngFor="let message of getAsyncMessages(control.errors, 'asyncWarnings')" [style.color]="'#f5a623'">{{ message }}</div>
      </ng-container>
    </ng-container>
  `
})
export class FormControlErrorComponent {
  @Input() control: FormControl<unknown> | FormGroup<unknown> ;
  @Input() errorHandlers: FormControlErrorHandler[] = [];
  @Input() checkTouched = false;
  hasAsyncErrors = hasAsyncErrors;
  getAsyncMessages = getAsyncMessages;
  applyMessage = (
      message: string | ((formControl: FormControl<unknown> | FormGroup<unknown>) => string)
  ): string => typeof message === 'function' ? message(this.control) : message.toString();

  get isInvalidState(): boolean {
    const isDirty = () => this.control.dirty || (this.checkTouched ? this.control.touched : false);

    return isRealValue(this.control) && this.control.invalid && isDirty();
  }
}
