import {
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { AsyncValidatorFn, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  FormControlErrorHandler,
  getValueByMultiplier,
  isEmptyValue,
  isNullOrFalse,
  isNullOrUndefined,
  isRealValue,
  ValidatorType,
  WidgetErrorStateMatcher,
} from '@tr-common';

import {
  checkAsyncWarnings,
  getErrorHandlers,
  hasOnlyAsyncWarnings,
  SourceChange,
  SourceChangeType,
  StudyOptionLimits,
  WidgetModify,
  WidgetPrototype,
} from '../../../models';
import { StudyOption } from '../../../models/study-option';

@Component({
  selector: 'lib-numerical-widget',
  templateUrl: './numerical-widget.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./numerical-widget.component.scss']
})
export class NumericalWidgetComponent implements WidgetPrototype<number | boolean>, OnChanges, OnDestroy {
  @Input() option: StudyOption;
  @Input() value: number | boolean;
  @Input() asyncValidators: AsyncValidatorFn[];
  @Output() modify = new EventEmitter<WidgetModify<number | boolean>>();
  placeholder = 'Enter value';
  hasOnlyAsyncWarnings = hasOnlyAsyncWarnings;
  valid: ValidatorType = 'default';
  limits: StudyOptionLimits;
  input = new FormControl<number>(null, {updateOn: 'blur'});
  errorMatcher = new WidgetErrorStateMatcher();
  errorHandlers: FormControlErrorHandler[] = [];
  subscriptions = new Subscription();

  constructor() {
    this.subscriptions.add(
      this.input.statusChanges.pipe(
        map(x => checkAsyncWarnings(x as ValidatorType, this.input.errors)),
      ).subscribe((status: ValidatorType) => {
        const newValue = this.input.value;

        this.valid = isNullOrFalse(newValue) ? 'VALID' : status;
        if (this.value !== newValue) {
          this.emitState(SourceChange.user);
        }
      })
    );
  }

  @HostBinding('class.invalid') get isValid() { return this.valid === 'INVALID'; }
  @HostListener('click') widgetClick = () => this.emitState(SourceChange.click);

  ngOnChanges({option, value, asyncValidators}: SimpleChanges): void {
    if (isRealValue(option)) {
      const decimal = this.option?.isFloat ? {decimal: 3} : null;

      this.limits = new StudyOptionLimits({...this.option?.numeric_limits, ...decimal});
      this.errorHandlers = getErrorHandlers(this.limits);
      this.input.setValidators(Validators.compose([
        ...this.limits.getMinMaxValidators, this.option.isFloat ? this.limits.floatValidator : this.limits.integerValidator
      ]));
    }
    if (isRealValue(value)) {
      const newValue: boolean | number = this.value;

      if (!isEmptyValue(newValue)) {
        this.input.setValue(typeof newValue === 'boolean' ? null : +newValue, {emitEvent: false});
        this.input.markAsDirty();
      } else if (isEmptyValue(newValue) && !value.firstChange) {
        this.input.reset();
      }
    }
    if (isRealValue(asyncValidators)) {
      this.input.setAsyncValidators(this.asyncValidators);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  increment(multiplier = 1): void {
    this.input.setValue(getValueByMultiplier(this.input, multiplier, this.option.isFloat));
  }

  emitState(source: SourceChangeType): void {
    const {valid, input: {value}} = this;

    this.modify.emit({value: isNullOrUndefined(value) ? false : value, valid, source});
  }
}
