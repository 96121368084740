import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { AsyncValidatorFn } from '@angular/forms';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { Memoize } from 'lodash-decorators';
import { Subscription } from 'rxjs';

import { answerWidget, convertStringToNum, isEmptyValue, isRealValue } from '@tr-common';

import {
  FlatTreeNode,
  getChildrenFromTreeNode,
  SourceChange,
  StudyOptionValidatorType,
  TreeNode,
  TreeNodeValueType,
  whenWidgetIs,
  WidgetModify,
} from '../../../models';
import { StudyOption } from '../../../models/study-option';
import { LayoutService } from '../../../services';
import {
  ActiveQuestionCollection,
  buildTree,
  DraftAnswer,
  DraftAnswersType,
  getAnswerValues,
  getFlatTreeNodeLevel,
  hasTheSameLevelRadio,
  isFlatTreeNodeExpandable,
  isValidByTreeControlState,
  markQuestionsAndOptions,
  treeNode2FlatTreeNode,
  validateAnswers,
} from '../../models';
import { WidgetValidatorsService } from '../../services/widget-validators.service';
import { StudyFacade } from '../../store';

@Component({
  selector: 'lib-question',
  templateUrl: './question.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./question.component.scss']
})
export class QuestionComponent implements OnChanges, OnDestroy {
  @Input() source: ActiveQuestionCollection;
  @Input() answerSaveError: string[];
  @Output() answer = new EventEmitter<DraftAnswer>();
  convertStringToNum = convertStringToNum;
  whenWidgetIs = whenWidgetIs;
  actualQuestionId: string = null;
  answerWidget = answerWidget;
  treeFlattener = new MatTreeFlattener<TreeNode, FlatTreeNode>(
    treeNode2FlatTreeNode, getFlatTreeNodeLevel, isFlatTreeNodeExpandable, getChildrenFromTreeNode
  );
  treeControl = new FlatTreeControl<FlatTreeNode>(getFlatTreeNodeLevel, isFlatTreeNodeExpandable);
  dataSource = new MatTreeFlatDataSource<TreeNode, FlatTreeNode>(this.treeControl, this.treeFlattener);
  unfilledList: string[] = [];
  // no need to have dirty answers outside, it will cost a re-rendering for us. we can clear them here
  draftAnswers: DraftAnswersType = [];
  // used to have local data of question answers because of correct selected answers at this point of time
  private subscriptions = new Subscription();

  constructor(
    public validatorService: WidgetValidatorsService,
    public layout: LayoutService,
    private studyFacade: StudyFacade
  ) {
    this.subscriptions.add(
      this.studyFacade.showHint$.subscribe((showHint: boolean) => {
        // FIXME This subscribe calls a 2 step sequence with values false and undefined
        if (isRealValue(showHint) && isRealValue(this.treeControl.dataNodes)) {
          markQuestionsAndOptions(this.treeControl.dataNodes, this.unfilledList);
        }
      })
    );
  }

  @Memoize() asyncValidators({option_validators}: StudyOption): AsyncValidatorFn[] {
    return this.validatorService.convert(option_validators as StudyOptionValidatorType[], this.source.participant);
  }

  get isValidByTreeControlState(): boolean {
    return isValidByTreeControlState(this.treeControl);
  }

  whenIsQuestion = (x: number, node: FlatTreeNode) => node.isQuestion;
  whenIsDropdown = (x: number, node: FlatTreeNode) => node.isDropDown;
  whenIsAutocomplete = (x: number, node: FlatTreeNode) => node.isAutocompleteOptions;
  whenIsMultiselect = (x: number, node: FlatTreeNode) => node.isMultiselectOptions;
  whenIsMatrixScale = (x: number, node: FlatTreeNode) => isRealValue(node.matrixScaleOptions);

  ngOnChanges({source}: SimpleChanges): void {
    if (isRealValue(source)) {
      const isNewQuestion = this.actualQuestionId !== this.source.activeQuestionId;

      // console.log({handler: 'ngOnChanges', source: this.source, actualQuestionId: this.actualQuestionId});
      this.draftAnswers = [];
      if (isNewQuestion) {
        this.dataSource.data = this.buildTree();
        this.actualQuestionId = this.source.activeQuestionId;
      }
      this.refreshTree(isNewQuestion);
    }
    this.unfilledList = validateAnswers(this.treeControl.dataNodes, this.source.optionQuestionMap);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onModify(widget: string, event: WidgetModify<TreeNodeValueType>, node: FlatTreeNode): void {
    if (node.value === event.value && node.validStatus === event.valid) return;
    const toExchangeWithParent = event.source === SourceChange.user
      || event.source === SourceChange.click && isEmptyValue(node.value) && hasTheSameLevelRadio(this.treeControl.dataNodes, node);

    node.validStatus = event.valid;
    // console.log({handler: 'onModify', widget, ...event, node, toExchangeWithParent});
    switch (widget) {
      case 'label-widget':
        node.value = event.value;
        break;
      case 'radio-with-text-widget':
      case 'radio-widget':
        node.value = event.value;
        if (isRealValue(event.value) && event.source === SourceChange.user) {
          node.unselectOtherRadioButtons(this.treeControl.dataNodes);
        }
        break;
      case 'matrix-scale-widget':
      case 'likert-scale-widget':
      case 'date-range-widget':
      case 'date-widget':
      case 'date-short-widget':
      case 'zip-widget':
      case 'text-widget':
      case 'numerical-widget':
      case 'checkbox-with-text-widget':
      case 'checkbox-widget':
      case 'dropdown-widget':
      case 'a1c-widget':
        node.value = event.value;
        this.clearSibling(event, node);
        if (!event.value) {
          this.treeControl.getDescendants(node).forEach((child: FlatTreeNode) => child.value = child.clearValue);
        }
        break;
      case 'autocomplete-widget':
        node.autocomplete = event.value;
        this.clearSibling(event, node);
        break;
      case 'multiselect-widget':
        node.multiselect = event.value;
        this.clearSibling(event, node);
        break;
      default:
        console.warn(`Unknown widget: ${widget}`);
    }
    this.draftAnswers = getAnswerValues(this.treeControl.dataNodes);
    this.unfilledList = validateAnswers(this.treeControl.dataNodes, this.source.optionQuestionMap);
    if (toExchangeWithParent) {
      this.answer.emit({answers: this.draftAnswers, validity: this.unfilledList.length === 0});
      if (this.studyFacade.showHint$.value && this.unfilledList.length > 0) {
        markQuestionsAndOptions(this.treeControl.dataNodes, this.unfilledList);
      }
      this.refreshTree();
    }
  }

  private clearSibling({value, source}: WidgetModify<TreeNodeValueType>, node: FlatTreeNode): void {
    if (isRealValue(value) || source === SourceChange.click) {
      node.clearSiblingRadio(this.treeControl.dataNodes);
    }
  }

  private refreshTree(reInit = false): void {
    if (reInit) {
      this.treeControl.collapseAll();
    }
    this.treeControl.dataNodes.filter(({expandable}: FlatTreeNode) => expandable).forEach(n => {
      if (isEmptyValue(n.value)) {
        this.treeControl.collapse(n);
      } else {
        this.treeControl.expand(n);
      }
    });
  }
  // It should be called only one time when a parent question is changed by system
  private buildTree(showHint?: boolean): TreeNode[] {
    const {source: {parentQuestion, optionQuestionMap, questionAnswers}, draftAnswers, unfilledList} = this;

    return buildTree({
      question: parentQuestion, questionAnswers, optionQuestionMap, draftAnswers, unfilledList, showHint
    }, 0);
  }
}
